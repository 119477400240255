import React from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Pagination from "../../components/common/Pagination";
import AddBtn from "../../components/common/AddBtn";
import {
    view_all_table,
    PAGE_TITLE,
    PAGE_SINGLE_TITLE,
    LINK_URL,
    SIDEBAR_OPTIONS,
    inputFields,
} from "../../shared/enums/webpages_enum";
import DataTable from "../../components/common/DataTable";
import {
    useAllWebpages,
    useGetDropdownOptions,
} from "../../shared/hooks/UseWebpage";
import SidebarFilter from "../../components/common/SidebarFilter";
import { URI } from "../../domain/constant";
import { Link } from "react-router-dom";
import TableViewBtn from "../../components/common/TableViewBtn";
import TableEditBtn from "../../components/common/TableEditBtn";
import TableDeleteBtn from "../../components/common/TableDeleteBtn";
const AllWebpages = ({ }) => {
    const [data, setPageNumber, deleteBtnClicked] = useAllWebpages();
    const { webpages_loading, webpages, total_webpages, page, pages } = data;
    const [dropdownOptions, loadOptions] = useGetDropdownOptions();

    return (
        <div className="pace-done">
            <div>
                <Header />
                <BreadCrumb
                    title={PAGE_TITLE}
                    mainLinkTitle="Dashboard"
                    mainLinkUrl="/dashboard"
                    activeLink={PAGE_TITLE}
                />

                <div className="container-fluid">
                    <div className="row">
                        {SIDEBAR_OPTIONS && (
                            <SidebarFilter
                                SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                                dropdown_options={dropdownOptions}
                                loadOptions={loadOptions}
                            />
                        )}
                        <div className="col-lg-10">
                            <div
                                className="card"
                                style={{ boxShadow: "rgb(227 233 243) 0px 4px 22px" }}
                            >
                                <div className="card-body">
                                    <AddBtn item={LINK_URL} title={PAGE_SINGLE_TITLE} />
                                    {total_webpages} records found
                                    <div>
                                        <Pagination
                                            data={webpages}
                                            page={page}
                                            pages={pages}
                                            count={total_webpages}
                                            setPage={setPageNumber}
                                            loading={webpages_loading}
                                        /></div>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th> # </th>
                                                    <th> Blog Featured Image </th>
                                                    <th> Blog Featured Title </th>
                                                    <th> Blog Featured Short Desc </th>
                                                    <th> Action </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {webpages &&
                                                    webpages.map((item, webpage_index) => {
                                                        return (
                                                            <tr>
                                                                <td>{webpage_index + 1 + (page - 1) * 100} </td>
                                                                <td>
                                                                    <img
                                                                        src={`${URI}${item.blog_featured_img}`}
                                                                        style={{
                                                                            width: "75px",
                                                                            height: "75px",
                                                                            objectFit: "cover",
                                                                            borderRadius: "75px",
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>{item.blog_featured_title}</td>
                                                                <td>{item.blog_short_description}</td>
                                                                <td>
                                                                    {
                                                                        // <TableViewBtn id={item._id} item={LINK_URL} />
                                                                    }
                                                                    <TableEditBtn id={item._id} item={LINK_URL} />
                                                                    <TableDeleteBtn
                                                                        id={item._id}
                                                                        deleteBtnClicked={deleteBtnClicked}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                {webpages && webpages.length === 0 && (
                                                    <tr>
                                                        <td colSpan={20}>No result found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        {/* <DataTable
                      keys={view_all_table}
                      data={products}
                      field={LINK_URL}
                      page={page}
                      deleteBtnClicked={deleteBtnClicked}
                      loading={products_loading}
                      inputFields={inputFields}
                      PAGE_TITLE={PAGE_TITLE}
                      PAGE_SINGLE_TITLE={PAGE_SINGLE_TITLE}
                    /> */}

                                        <Pagination
                                            data={webpages}
                                            page={page}
                                            pages={pages}
                                            count={total_webpages}
                                            setPage={setPageNumber}
                                            loading={webpages_loading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllWebpages;
