import { combineReducers } from "redux";
import alert from "./alert_reducer";
import auth from "./auth_reducer";
import { banner_reducer } from "./banner_reducer";
import { contact_reducer } from "./contact_reducer";
import { product_reducer } from "./product_reducer";
import { category_reducer } from "./category_reducer";
import { blog_reducer } from "./blog_reducer";
import { customer_reducer } from "./customer_reducer";
import { collection_reducer } from "./collection_reducer";
import { notification_reducer } from "./notification_reducer";
import { dashboard_reducer } from "./dashboard_reducer";
import { mobilebanner_reducer } from "./mobilebanner_reducer";

import { menu_reducer } from "./menu_reducer";
import { productcategory_reducer } from "./productcategory_reducer";
import { setting_reducer } from "./setting_reducer";
import { page_reducer } from "./page_reducer";
import { webpage_reducer } from "./webpage_reducer";
import { presswork_reducer } from "./presswork_reducer";


export default combineReducers({
  alert,
  auth,
  banner: banner_reducer,
  contact: contact_reducer,
  product: product_reducer,
  category: category_reducer,
  blog: blog_reducer,
  customer: customer_reducer,
  collection: collection_reducer,
  notification: notification_reducer,
  dashboard: dashboard_reducer,
  mobilebanner: mobilebanner_reducer,
  menu: menu_reducer,
  productcategory: productcategory_reducer,
  setting: setting_reducer,
  page: page_reducer,
  webpage: webpage_reducer,
  presswork: presswork_reducer,

});
