export const PAGE_TITLE = "Pressworks";
export const PAGE_SINGLE_TITLE = "Presswork";
export const LINK_URL = "pressworks";
export const inputFields = {
    title: {
        type: "string",
        required: false,
        title: "Title",
        inputType: "text",
        slug: true,

    },
    // slug: {
    //     type: "slug",
    //     required: false,
    //     title: "Slug",
    //     inputType: "text",
    // },
    image: {
        type: "file",
        required: false,
        title: "Image",
        inputType: "text",
    },


};
export const initialValues = {
    title: "",
};

export const view_all_table = [
    { name: "Title", value: "title" },
    { name: "Image", value: "image", image: true },
];

export const SIDEBAR_OPTIONS = [
    {
        id: "title",
        field: "title",
        label: "Title",
        type: "string",
        search_type: "search",
        inputType: "text",
        condition: "",
    },
];
