import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import PageForm from "../../components/pages/PageForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/pages_enum";
import {
  useCreatePage,
  useGetDropdownOptions,
} from "../../shared/hooks/UsePage";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import {
  TextInput,
  HTMLEditor,
  SelectBox,
  FileUpload
} from "../../components/Form/Form";
// import { useSelectAllPage } from "../../shared/hooks/UsePage";

const AddPage = ({ }) => {
  let history = useHistory();
  const [page, addData] = useCreatePage();
  const { add_page_loading } = page;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);

  const submitFormClicked = async (values) => {
    await addData(values);
    history.push(`/${LINK_URL}`);
  };

  const [dropdownOptions, loadOptions] = useGetDropdownOptions();

  const [showContentType, setShowContentType] = useState(false);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Add ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Add"
        />
      </div>
      <Formik
        initialValues={{
          banner_image: "",
          banner_title: "",
          banner_sub_heading: "",
          component_type: [{
            image: "",
            text: "",
            component_field_type: "",
            cols: ""
          }]

        }}
        validationSchema={Yup.object({
          banner_title: Yup.string().required("Required"),
        })}
        onSubmit={async (
          values,
          { setSubmitting, resetForm }
        ) => {
          setSubmitting(true);
          await submitFormClicked(values)
          resetForm();
          setSubmitting(false);
        }}
      >
        {(formik) => {
          console.log(formik);
          return (
            <Form>
              <section>
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      Dynamic Page
                    </h4>
                    <p className="card-title-description">
                      Enter Details to add Page
                    </p>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <Form>
                        <div className="row">
                          <div className="col-md-6">
                            <FileUpload
                              title="Banner Image"
                              item="banner_image"
                              formik={formik}
                              value={formik.values.banner_image}
                            />

                          </div>
                          <div className="col-md-6">
                            <TextInput
                              label="Banner Title"
                              name="banner_title"
                              type="text"
                              placeholder="Enter Banner Title"
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInput
                              label="Banner Sub Heading"
                              name="banner_sub_heading"
                              type="text"
                              placeholder="Enter Banner Sub Heading"
                            />
                          </div>


                          <h5>Component Type</h5>
                          <FieldArray
                            name="component_type"
                            render={(arrayHelpers) => (
                              <div>
                                {formik.values.component_type.map((friend, index) => (
                                  <div key={index}>
                                    {/* <input
                            name={`todos[${index}].name`}
                            value={formik.values.todos[index].name}
                            onChange={formik.handleChange}
                          /> */}
                                    <div className="d-flex justify-content-between" style={{
                                      background: "#f1f1f1", padding
                                        : "10px 10px",
                                      marginBottom: "10px",
                                      marginTop: "10px"
                                    }}>
                                      <div className="label-featu" >
                                        Component Type {index + 1}
                                      </div>
                                      <div className="closebtn">
                                        <a onClick={() => arrayHelpers.remove(index)}>
                                          X
                                        </a>
                                      </div>
                                    </div>
                                    <div className="row">

                                      <div className="col-md-6">
                                        <SelectBox
                                          label="Component Field Type"
                                          name={`component_type[${index}].component_field_type`}
                                          placeholder="Enter Component Field Type">
                                          <option value=""> --Select Component Type-- </option>
                                          <option value="image"> Image </option>
                                          <option value="text"> Text </option>

                                        </SelectBox>
                                      </div>

                                      <div className="col-md-6">
                                        <SelectBox
                                          label="Cols"
                                          name={`component_type[${index}].cols`}
                                          placeholder="Enter Cols">
                                          <option value="">-- Select-- </option>
                                          <option value={1}> 1 </option>
                                          <option value={2}> 2 </option>
                                          <option value={3}> 3 </option>
                                          <option value={4}> 4 </option>
                                          <option value={5}> 5 </option>
                                          <option value={6}> 6 </option>
                                          <option value={7}> 7 </option>
                                          <option value={8}> 8 </option>
                                          <option value={9}> 9 </option>
                                          <option value={10}> 10 </option>
                                          <option value={11}> 11 </option>
                                          <option value={12}> 12 </option>
                                        </SelectBox>
                                      </div>


                                      {formik.values.component_type[index].component_field_type === "text" && (
                                        <div className="col-md-12">

                                          <HTMLEditor
                                            title="Text"
                                            item={`component_type[${index}].text`}

                                            formik={formik}
                                            value={formik.values.component_type[index].text}
                                          />
                                        </div>
                                      )}

                                      {formik.values.component_type[index].component_field_type === "image" && (
                                        <div className="col-md-6">

                                          <FileUpload
                                            title="Image"
                                            item={`component_type[${index}].image`}
                                            formik={formik}
                                            value={formik.values.component_type[index].image}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ))}
                                <button
                                  type="submit"
                                  className="btn primary-btn btn btn-warning mt-3"
                                  onClick={() => arrayHelpers.push({
                                    image: "",
                                    text: "",
                                    component_field_type: "",
                                    cols: ""
                                  })}
                                >
                                  Add Component
                                </button>
                              </div>
                            )}
                          />

                        </div>
                      </Form>
                    </div>

                    <div className="row">
                      <div className="col-md-12 text-center m-3">
                        <button type="submit" className="btn btn-success">
                          {formik.isSubmitting
                            ? 'Processing...'
                            : 'Save'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddPage;
