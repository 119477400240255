export const PAGE_TITLE = "Collections";
export const PAGE_SINGLE_TITLE = "Collection";
export const LINK_URL = "collections";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    slug: true,
  },
  hide_on_menu: {
    type: "checkbox",
    required: false,
    title: "Hide On Menu",
  },
  description: {
    type: "html",
    required: false,
    title: "Description",
  },

  brand: {
    type: "select",
    required: false,
    title: "Brand",
    options: ["None", "Asha Gautam", "GG By Asha Asha Gautam"]
  },
  collection_banner_img: {
    type: "file",
    required: false,
    title: "Collection Banner Image",
  },
  collection_mobile_banner_img: {
    type: "file",
    required: false,
    title: "Collection Mobile Banner Image",
  },
  youtube_shots_link: {
    type: "string",
    required: false,
    title: "Youtube Shots Link",
  },
  // youtube_video_link: {
  //   type: "string",
  //   required: false,
  //   title: "Youtube Video Link",
  // },
  featured_img: {
    type: "file",
    required: false,
    title: "Featured Image",
  },
  gallery: {
    type: "gallery",
    required: false,
    title: "Media Gallery",
    // inputType: "text",
  },
  slug: {
    type: "slug",
    required: false,
    title: "Slug",
  },
  youtube_footer_video_link: {
    type: "array",
    required: false,
    title: "Footer Youtube Links",
    fields: {
      ashagautam_youtube_link: {
        type: "string",
        required: false,
        title: "Youtube Video Link",
      },
    }
  },

};
export const initialValues = {
  name: "",
  slug: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Image", value: "collection_banner_img", image: true },

  // {
  //   name: "Parent ",
  //   value: "parent_collection",
  //   related: true,
  //   field: "name",
  // },
  // { name: "Dynamic Collection", value: "is_dynamic_collection", boolean: true },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
