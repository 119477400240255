import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import {
  TextInput,
  HTMLEditor,
  FileUpload
} from "../../components/Form/Form";
import { useCreateSetting, useGetSetting } from "../../shared/hooks/UseSetting.js"

const Settings = () => {

  let history = useHistory();
  const [setting_data] = useGetSetting()
  const [setting, addData] = useCreateSetting();
  const { settings, loading } = setting;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);

  const submitFormClicked = async (values) => {
    await addData(values);
    history.push(`/settings`);
  };

  return (
    <div className="pace-done">
      <Header />
      <BreadCrumb
        title={`Settings`}
        mainLinkTitle={"Settings"}
        mainLinkUrl={"/dashboard"}
        activeLink="Home"
      />
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {!loading && settings && (
                <Formik
                  initialValues={{
                    logo: settings.logo ? settings.logo : "",
                    facebook_link: settings.facebook_link ? settings.facebook_link : "",
                    instagram_link: settings.instagram_link ? settings.instagram_link : "",
                    twitter_link: settings.twitter_link ? settings.twitter_link : "",
                    linkedin_link: settings.linkedin_link ? settings.linkedin_link : "",
                    youtube_link: settings.youtube_link ? settings.youtube_link : "",
                    gg_facebook_link: settings.gg_facebook_link ? settings.gg_facebook_link : "",
                    gg_instagram_link: settings.gg_instagram_link ? settings.gg_instagram_link : "",
                    gg_twitter_link: settings.gg_twitter_link ? settings.gg_twitter_link : "",
                    gg_linkedin_link: settings.gg_linkedin_link ? settings.gg_linkedin_link : "",
                    gg_youtube_link: settings.gg_youtube_link ? settings.gg_youtube_link : "",
                    store_address: settings.store_address ? settings.store_address : "",
                    contact_no1: settings.contact_no1 ? settings.contact_no1 : "",
                    contact_no2: settings.contact_no2 ? settings.contact_no2 : "",
                    email_address: settings.email_address ? settings.email_address : "",
                    map_integrate_links: settings.map_integrate_links ? settings.map_integrate_links : "",
                    privacy_policy: settings.privacy_policy ? settings.privacy_policy : "",
                    refund_policy: settings.refund_policy ? settings.refund_policy : "",

                  }}
                  validationSchema={Yup.object({
                    logo: Yup.string().required("Required"),
                  })}
                  onSubmit={async (
                    values,
                    { setSubmitting, resetForm }
                  ) => {
                    setSubmitting(true);
                    await submitFormClicked(values)
                    resetForm();
                    setSubmitting(false);
                  }}
                >
                  {(formik) => {
                    console.log(formik);
                    return (
                      <>
                        <section>

                          <div className="card">
                            <div className="card-header">
                              <h4 className="card-title">
                                Setting
                              </h4>
                              <p className="card-title-description">
                                Enter Details to add Setting
                              </p>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <Form>
                                  <div className="row">
                                    <div className="col-md-6">

                                      <FileUpload
                                        title="Logo"
                                        item="logo"
                                        formik={formik}
                                        value={formik.values.logo}
                                      />
                                    </div>
                                    <div className="mt-3">
                                      <h5>Social Links For Asha Gautam:</h5>
                                    </div>
                                    <div className="col-md-6">
                                      <TextInput
                                        label="Facebook Link"
                                        name="facebook_link"
                                        type="text"
                                        placeholder="Enter Facebook Link"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <TextInput
                                        label="Instagram Link"
                                        name="instagram_link"
                                        type="text"
                                        placeholder="Enter Instagram Link"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <TextInput
                                        label="Twitter Link"
                                        name="twitter_link"
                                        type="text"
                                        placeholder="Enter Twitter Link"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <TextInput
                                        label="LinkedIn Link"
                                        name="linkedin_link"
                                        type="text"
                                        placeholder="Enter LinkedIn Link"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <TextInput
                                        label="Youtube Link"
                                        name="youtube_link"
                                        type="text"
                                        placeholder="Enter Youtube Link"
                                      />
                                    </div>
                                    <div className="mt-3">
                                      <h5>Social Links For GG By Asha Gautam:</h5>
                                    </div>
                                    <div className="col-md-6">
                                      <TextInput
                                        label="GG Gautam Facebook Link"
                                        name="gg_facebook_link"
                                        type="text"
                                        placeholder="Enter Facebook Link"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <TextInput
                                        label="GG Gautam Instagram Link"
                                        name="gg_instagram_link"
                                        type="text"
                                        placeholder="Enter Instagram Link"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <TextInput
                                        label="GG Gautam Twitter Link"
                                        name="gg_twitter_link"
                                        type="text"
                                        placeholder="Enter Twitter Link"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <TextInput
                                        label="GG Gautam LinkedIn Link"
                                        name="gg_linkedin_link"
                                        type="text"
                                        placeholder="Enter LinkedIn Link"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <TextInput
                                        label="GG Gautam Youtube Link"
                                        name="gg_youtube_link"
                                        type="text"
                                        placeholder="Enter Youtube Link"
                                      />
                                    </div>
                                    <div className="mt-3">
                                      <h5>Contact Details:</h5>
                                    </div>
                                    <div className="col-md-6">
                                      <TextInput
                                        label="Store Address"
                                        name="store_address"
                                        type="text"
                                        placeholder="Enter Store Address"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <TextInput
                                        label="Contact Number 1"
                                        name="contact_no1"
                                        type="text"
                                        placeholder="Enter Contact Number 1"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <TextInput
                                        label="Contact Number 2"
                                        name="contact_no2"
                                        type="text"
                                        placeholder="Enter Contact Number 2"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <TextInput
                                        label="Email Address"
                                        name="email_address"
                                        type="text"
                                        placeholder="Enter Email Address"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <TextInput
                                        label="Map Integrate Link"
                                        name="map_integrate_links"
                                        type="text"
                                        placeholder="Enter Map Integrate Link"
                                      />

                                    </div>
                                    <div className="col-md-12">
                                      <HTMLEditor
                                        title="Privacy Policy Content"
                                        item="privacy_policy"
                                        formik={formik}
                                        value={formik.values.privacy_policy}
                                      />
                                    </div>
                                    <div className="col-md-12">
                                      <HTMLEditor
                                        title="Refund Policy Content"
                                        item="refund_policy"
                                        formik={formik}
                                        value={formik.values.refund_policy}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-12 text-center m-3">
                                      <button type="submit" className="btn btn-success">
                                        {formik.isSubmitting
                                          ? 'Processing...'
                                          : 'Save'}
                                      </button>
                                    </div>
                                  </div>
                                </Form>
                              </div>
                            </div>
                          </div>
                        </section>
                      </>

                    );
                  }}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </section>


    </div>
  );
}

export default Settings;
