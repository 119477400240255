import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import FormComponents from "../common/FormComponents";
import moment from "moment";
import VariableOptions from "./VariableOptions";
import VariationTable from "./VariationTable";
import { CheckBox } from "../Form/Form";
function ProductForm({
  data,
  edit,
  submitForm,
  setFeaturedImage,
  setGallery,
  inputFields,
  initialValues,
  dropdown_options,
  loadOptions,
}) {
  let history = useHistory();
  let newItitianValues = {
    name: "",
    collections: [],
    slug: "",
    description: "",
    media: [],
    in_stock: true,
    color: "",
    size: "",
    craft: "",
    delivery_time: "",
    shipping: "",
    customization: "",
  };

  return (
    <div className="card-body">
      {edit ? (
        <div className="row">
          <Formik
            initialValues={{
              name: data.name,
              collections: data.collections,
              slug: data.slug,
              description: data.description,
              media: data.media,
              in_stock: true,
              color: data.color,
              size: data.size,
              craft: data.craft,
              delivery_time: data.delivery_time,
              shipping: data.shipping,
              customization: data.customization,
            }}
            validationSchema={Yup.object({
              name: Yup.string().required("Required"),
              collections: Yup.array().required("Required").min(1),

            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);

              await submitForm(values);
              setSubmitting(false);
              resetForm(true);
            }}
          >
            {(formik) => {
              console.log(formik.values);
              return (
                <Form>
                  <FormComponents
                    formik={formik}
                    inputFields={inputFields}
                    setFeaturedImage={setFeaturedImage}
                    dropdown_options={dropdown_options}
                    setGallery={setGallery}
                    edit={edit}
                    loadOptions={loadOptions}
                  />


                  <div className="row">
                    <div className="col-md-12 text-center m-3">
                      <button type="submit" className="btn btn-success">
                        {formik.isSubmitting
                          ? "Processing..."
                          : edit
                            ? "Edit"
                            : "Save"}
                      </button>
                      <a
                        className="btn btn-secondary m-3"
                        onClick={history.goBack}
                        href="#goback"
                      >
                        <i className="fa fa-angle-left"></i> Go Back
                      </a>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      ) : (
        <div className="row">
          <Formik
            initialValues={newItitianValues}
            validationSchema={Yup.object({
              name: Yup.string().required("Required"),
              collections: Yup.array().required("Required").min(1),

            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);

              await submitForm(values);
              setSubmitting(false);
              resetForm(true);
            }}
          >
            {(formik) => {
              console.log(JSON.stringify(formik.values));
              return (
                <Form>
                  <FormComponents
                    formik={formik}
                    inputFields={inputFields}
                    setFeaturedImage={setFeaturedImage}
                    dropdown_options={dropdown_options}
                    setGallery={setGallery}
                    edit={edit}
                    loadOptions={loadOptions}
                  />


                  <div className="row">
                    <div className="col-md-12 text-center m-3">
                      <button type="submit" className="btn btn-success">
                        {formik.isSubmitting
                          ? "Processing..."
                          : edit
                            ? "Edit"
                            : "Save"}
                      </button>
                      <a
                        className="btn btn-secondary m-3"
                        onClick={history.goBack}
                        href="#goback"
                      >
                        <i className="fa fa-angle-left"></i> Go Back
                      </a>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
}

export default ProductForm;
