import React from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";


function Dashboard() {

  return (
    <div className="pace-done">
      <Header />
      <section className="dashboard-welcome">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="dashboard-welcome-text">
                <h4>
                  Welcome to Asha Gautam & GG By Asha Gautam Dashboard
                </h4>
                <p>Here you can manage all your website components*</p>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}

export default Dashboard;
