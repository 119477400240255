export const MENU = [
  {
    label: "Dashboard",
    link: "/dashboard",
    roles: ["SUPER ADMIN"],
  },
  {
    label: "Products",
    link: "#",
    roles: ["SUPER ADMIN"],
    menu: [
      {
        label: "Collections",
        link: "/collections",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Products",
        link: "/products",
        roles: ["SUPER ADMIN"],
      },
    ],
  },

  {
    label: "Blogs",
    link: "#",
    roles: ["SUPER ADMIN"],
    menu: [

      {
        label: "Blogs",
        link: "/webpages",
        roles: ["SUPER ADMIN"],
      },
    ],
  },
  {
    label: "Banners",
    link: "#",
    roles: ["SUPER ADMIN"],
    menu: [
      {
        label: "Banners",
        link: "/banners",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Mobile Banners",
        link: "/mobilebanners",
        roles: ["SUPER ADMIN"],
      },

    ],
  },
  {
    label: "Press",
    link: "#",
    roles: ["SUPER ADMIN"],
    menu: [
      {
        label: "Press Releases",
        link: "/pressworks",
        roles: ["SUPER ADMIN"],
      },
    ],
  },
  {
    label: "Settings",
    link: "#",
    roles: ["SUPER ADMIN"],
    menu: [
      {
        label: "Settings",
        link: "/settings",
        roles: ["SUPER ADMIN"],
      },
      // {
      //   label: "WebPages",
      //   link: "/webpages",
      //   roles: ["SUPER ADMIN"],
      // },

    ],
  },
];
