import React, { useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import WebpageForm from "../../components/webpages/WebpageForm";
import { convertToFormData } from "../../shared/upload";
import {
    initialValues,
    inputFields,
    PAGE_TITLE,
    PAGE_SINGLE_TITLE,
    LINK_URL,
} from "../../shared/enums/webpages_enum";
import {
    useUpdateWebpage,
    useSingleWebpage,
    useGetDropdownOptions,
} from "../../shared/hooks/UseWebpage";
// import { useSelectAllBlog } from "../../shared/hooks/UseBlog";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import {
    TextInput,
    HTMLEditor,
    SelectBox,
    FileUpload
} from "../../components/Form/Form";
import slugify from "react-slugify";

const EditWebpage = ({ match }) => {
    let history = useHistory();
    const [single_data] = useSingleWebpage(match.params.id);
    const [updateData] = useUpdateWebpage();
    const { webpage_loading, webpage, edit_webpage_loading } = single_data;
    const [featuredImage, setFeaturedImage] = useState(null);

    const [dropdownOptions, loadOptions] = useGetDropdownOptions();
    const submitFormClicked = async (values) => {
        await updateData(webpage._id, values);
        // history.push(`/${LINK_URL}/${webpage._id}/view`);
        history.push(`/${LINK_URL}`);
    };

    return (
        <div className="pace-done">
            <div>
                <Header />
                <BreadCrumb
                    title={`Edit ${PAGE_SINGLE_TITLE}`}
                    mainLinkTitle={PAGE_TITLE}
                    mainLinkUrl={LINK_URL}
                    activeLink="Edit"
                />
            </div>
            <div className="container-fluid">
                <div className="col-lg-12">
                    <div className="card">
                        {!webpage_loading ? (
                            webpage && (
                                <div>
                                    <div className="card-header">
                                        <div>
                                            <h4 className="card-title">
                                                {webpage[Object.keys(inputFields)[0]]} - <span>Edit</span>{" "}
                                            </h4>
                                            <p className="card-title-desc">
                                                <Link
                                                    to={`/${LINK_URL}`}
                                                    className="btn btn-soft-light"
                                                >
                                                    <i className="fa fa-angle-left"></i> {webpage.banner_title}
                                                </Link>
                                                <Link
                                                    to={`/${LINK_URL}/${webpage._id}/view`}
                                                    className="btn btn-soft-light"
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                    <Formik
                                        initialValues={{
                                            blog_featured_img: webpage ? webpage.blog_featured_img : "",
                                            blog_featured_title: webpage ? webpage.blog_featured_title : "",
                                            blog_short_description: webpage ? webpage.blog_short_description : "",
                                            blog_date: webpage ? webpage.blog_date : "",
                                            banner_image: webpage ? webpage.banner_image : "",
                                            banner_title: webpage ? webpage.banner_title : "",
                                            banner_sub_heading: webpage ? webpage.banner_sub_heading : "",
                                            component_type: webpage ? webpage.component_type : [{
                                                image: "",
                                                text: "",
                                                component_field_type: "",
                                                cols: ""
                                            }]

                                        }}
                                        validationSchema={Yup.object({
                                            blog_featured_title: Yup.string().required("Required"),
                                            banner_title: Yup.string().required("Required"),
                                        })}
                                        onSubmit={async (
                                            values,
                                            { setSubmitting, resetForm }
                                        ) => {
                                            setSubmitting(true);
                                            await submitFormClicked(values)
                                            resetForm();
                                            setSubmitting(false);
                                        }}
                                    >
                                        {(formik) => {
                                            console.log(formik);
                                            return (
                                                <Form>
                                                    <section>
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <h4 className="card-title">
                                                                    Dynamic WebPage
                                                                </h4>
                                                                <p className="card-title-description">
                                                                    Enter Details to add WebPage
                                                                </p>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <>
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <FileUpload
                                                                                    title="Blog Featured Image"
                                                                                    item="blog_featured_img"
                                                                                    formik={formik}
                                                                                    value={formik.values.blog_featured_img}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <TextInput
                                                                                    label="Blog Featured Title"
                                                                                    name="blog_featured_title"
                                                                                    type="text"
                                                                                    placeholder="Enter Blog Featured Title"
                                                                                    onChange={(e) => {
                                                                                        formik.setFieldValue("blog_featured_title", (e.target.value))
                                                                                        formik.setFieldValue("slug", slugify(e.target.value))
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <TextInput
                                                                                    label="Blog Short Description"
                                                                                    name="blog_short_description"
                                                                                    type="text"
                                                                                    placeholder="Enter Blog Short Description"
                                                                                    onChange={(e) => {
                                                                                        formik.setFieldValue("blog_short_description", (e.target.value))
                                                                                        // formik.setFieldValue("slug", slugify(e.target.value))
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <TextInput
                                                                                    label="Blog Date"
                                                                                    name="blog_date"
                                                                                    type="date"
                                                                                    placeholder="Enter Blog Date"
                                                                                    onChange={(e) => {
                                                                                        formik.setFieldValue("blog_date", (e.target.value))
                                                                                        // formik.setFieldValue("slug", slugify(e.target.value))
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <TextInput
                                                                                    label="Slug"
                                                                                    name="slug"
                                                                                    type="text"
                                                                                    placeholder="Enter Slug"
                                                                                    onChange={(e) => {
                                                                                        formik.setFieldValue("slug", slugify(e.target.value))
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="d-flex justify-content-between" style={{
                                                                                background: "#f1f1f1",
                                                                                padding: "10px 10px",
                                                                                marginBottom: "10px",
                                                                                marginTop: "10px"
                                                                            }}>
                                                                                <div className="label-featu" >
                                                                                    Blog Page Content
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <FileUpload
                                                                                    title="Banner Image"
                                                                                    item="banner_image"
                                                                                    formik={formik}
                                                                                    value={formik.values.banner_image}
                                                                                />

                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <TextInput
                                                                                    label="Banner Title"
                                                                                    name="banner_title"
                                                                                    type="text"
                                                                                    placeholder="Enter Banner Title"
                                                                                    onChange={(e) => {

                                                                                        formik.setFieldValue("banner_title", (e.target.value))
                                                                                        // formik.setFieldValue("slug", slugify(e.target.value))
                                                                                    }}
                                                                                />
                                                                            </div>

                                                                            {
                                                                                //     <div className="col-md-6">
                                                                                //     <TextInput
                                                                                //         label="Banner Sub Heading"
                                                                                //         name="banner_sub_heading"
                                                                                //         type="text"
                                                                                //         placeholder="Enter Banner Sub Heading"
                                                                                //     />
                                                                                // </div>
                                                                            }


                                                                            <h5>Component Type</h5>
                                                                            <FieldArray
                                                                                name="component_type"
                                                                                render={(arrayHelpers) => (
                                                                                    <div>
                                                                                        {formik.values.component_type.map((friend, index) => (
                                                                                            <div key={index}>
                                                                                                {/* <input
                                                name={`todos[${index}].name`}
                                                value={formik.values.todos[index].name}
                                                onChange={formik.handleChange}
                                              /> */}
                                                                                                <div className="d-flex justify-content-between" style={{
                                                                                                    background: "#f1f1f1", padding
                                                                                                        : "10px 10px",
                                                                                                    marginBottom: "10px",
                                                                                                    marginTop: "10px"
                                                                                                }}>
                                                                                                    <div className="label-featu" >
                                                                                                        Component Type {index + 1}
                                                                                                    </div>
                                                                                                    <div className="closebtn">
                                                                                                        <a onClick={() => arrayHelpers.remove(index)}>
                                                                                                            X
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">

                                                                                                    <div className="col-md-6">
                                                                                                        <SelectBox
                                                                                                            label="Component Field Type"
                                                                                                            name={`component_type[${index}].component_field_type`}
                                                                                                            placeholder="Enter Component Field Type">
                                                                                                            <option value=""> --Select Component Type-- </option>
                                                                                                            <option value="image"> Image </option>
                                                                                                            <option value="text"> Text </option>

                                                                                                        </SelectBox>
                                                                                                    </div>

                                                                                                    <div className="col-md-6">
                                                                                                        <SelectBox
                                                                                                            label="Cols"
                                                                                                            name={`component_type[${index}].cols`}
                                                                                                            placeholder="Enter Cols">
                                                                                                            <option value="">-- Select-- </option>
                                                                                                            <option value={1}> 1 </option>
                                                                                                            <option value={2}> 2 </option>
                                                                                                            <option value={3}> 3 </option>
                                                                                                            <option value={4}> 4 </option>
                                                                                                            <option value={5}> 5 </option>
                                                                                                            <option value={6}> 6 </option>
                                                                                                            <option value={7}> 7 </option>
                                                                                                            <option value={8}> 8 </option>
                                                                                                            <option value={9}> 9 </option>
                                                                                                            <option value={10}> 10 </option>
                                                                                                            <option value={11}> 11 </option>
                                                                                                            <option value={12}> 12 </option>
                                                                                                        </SelectBox>
                                                                                                    </div>


                                                                                                    {formik.values.component_type[index].component_field_type === "text" && (
                                                                                                        <div className="col-md-12">

                                                                                                            <HTMLEditor
                                                                                                                title="Text"
                                                                                                                item={`component_type[${index}].text`}

                                                                                                                formik={formik}
                                                                                                                value={formik.values.component_type[index].text}
                                                                                                            />
                                                                                                        </div>
                                                                                                    )}

                                                                                                    {formik.values.component_type[index].component_field_type === "image" && (
                                                                                                        <div className="col-md-6">

                                                                                                            <FileUpload
                                                                                                                title="Image"
                                                                                                                item={`component_type[${index}].image`}
                                                                                                                formik={formik}
                                                                                                                value={formik.values.component_type[index].image}
                                                                                                            />
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        ))}
                                                                                        <a
                                                                                            // type="button"
                                                                                            className="btn primary-btn btn btn-warning mt-3"
                                                                                            onClick={() => arrayHelpers.push({
                                                                                                image: "",
                                                                                                text: "",
                                                                                                component_field_type: "",
                                                                                                cols: ""
                                                                                            })}
                                                                                        >
                                                                                            Add Component
                                                                                        </a>
                                                                                    </div>
                                                                                )}
                                                                            />

                                                                        </div>
                                                                    </>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-md-12 text-center m-3">
                                                                        <button type="submit" className="btn btn-success">
                                                                            {formik.isSubmitting
                                                                                ? 'Processing...'
                                                                                : 'Save'}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                            )
                        ) : (
                            <div className="text-center">
                                <Spinner />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditWebpage;
