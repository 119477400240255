import api from "../../domain/api";
import {
    GET_PRESSWORKS_STATED,
    GET_PRESSWORKS,
    GET_PRESSWORKS_ENDED,
    ADD_PRESSWORK_STATED,
    ADD_PRESSWORK,
    ADD_PRESSWORK_ENDED,
    EDIT_PRESSWORK_STATED,
    EDIT_PRESSWORK,
    EDIT_PRESSWORK_ENDED,
    GET_PRESSWORK_STATED,
    GET_PRESSWORK,
    GET_PRESSWORK_ENDED,
    GET_ALL_PRESSWORKS_STATED,
    GET_ALL_PRESSWORKS,
    GET_ALL_PRESSWORKS_ENDED,
} from "../types/presswork_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addPresswork = (formData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_PRESSWORK_STATED,
        });
        const { data } = await api.post(`/pressworks`, formData);
        dispatch({
            type: ADD_PRESSWORK,
            payload: data,
        });
        dispatch({
            type: ADD_PRESSWORK_ENDED,
        });
    } catch (error) {
        dispatch({
            type: ADD_PRESSWORK_ENDED,
        });
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const getPressworks =
    ({ pageNumber = "" }) =>
        async (dispatch) => {
            try {
                dispatch({
                    type: GET_PRESSWORKS_STATED,
                });
                const queryParams = qs.parse(window.location.search.replace("?", ""));
                const query = qs.stringify(queryParams, {
                    encodeValuesOnly: true, // prettify url
                });

                const { data } = await api.get(`/pressworks?&${query}`);

                dispatch({
                    type: GET_PRESSWORKS,
                    payload: data,
                });
                dispatch({
                    type: GET_PRESSWORKS_ENDED,
                });
            } catch (error) {
                dispatch({
                    type: GET_PRESSWORKS_ENDED,
                });
                dispatch(handleErrorLocal(error));
                dispatch(handleError(error));
            }
        };
export const getPresswork = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GET_PRESSWORK_STATED,
        });
        const { data } = await api.get(`/pressworks/${id}`);

        dispatch({
            type: GET_PRESSWORK,
            payload: data,
        });
        dispatch({
            type: GET_PRESSWORK_ENDED,
        });
    } catch (error) {
        dispatch({
            type: GET_PRESSWORK_STATED,
        });
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const editPresswork = (id, formData) => async (dispatch) => {
    try {
        dispatch({
            type: EDIT_PRESSWORK_STATED,
        });
        const { data } = await api.put(`/pressworks/${id}`, formData);
        dispatch({
            type: EDIT_PRESSWORK,
            payload: data,
        });
        dispatch({
            type: EDIT_PRESSWORK_ENDED,
        });
    } catch (error) {
        dispatch({
            type: EDIT_PRESSWORK_ENDED,
        });
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const deletePresswork = (id) => async (dispatch) => {
    try {
        const { data } = await api.delete(`/pressworks/${id}`);
        dispatch(setAlert("Presswork Deleted Successfully", "success"));
    } catch (error) {
        dispatch(handleErrorLocal(error));
        dispatch(handleError(error));
    }
};
export const getAllPressworks =
    ({ term, value }) =>
        async (dispatch) => {
            try {
                dispatch({
                    type: GET_ALL_PRESSWORKS_STATED,
                });
                const { data } = await api.get(`/pressworks/all?term=${term}&value=${value}`);

                dispatch({
                    type: GET_ALL_PRESSWORKS,
                    payload: data,
                });
                dispatch({
                    type: GET_ALL_PRESSWORKS_ENDED,
                });
            } catch (error) {
                dispatch({
                    type: GET_ALL_PRESSWORKS_ENDED,
                });
                dispatch(handleErrorLocal(error));
                dispatch(handleError(error));
            }
        };

export const handleErrorLocal = () => async (dispatch) => { };
