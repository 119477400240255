export const PAGE_TITLE = "Products";
export const PAGE_SINGLE_TITLE = "Product";
export const LINK_URL = "products";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "text",
    slug: true,
  },
  collections: {
    type: "related",
    required: true,
    title: "Collections",
    inputType: "text",
    multiple: true,
  },
  slug: {
    type: "slug",
    required: true,
    title: "Slug",
    inputType: "text",
  },
  description: {
    type: "html",
    required: false,
    title: "Description",
    inputType: "text",
  },
  color: {
    type: "string",
    required: true,
    title: "Color",
    inputType: "text",
    slug: true,
  },
  size: {
    type: "string",
    required: true,
    title: "Size",
    inputType: "text",
    slug: true,
  },
  craft: {
    type: "string",
    required: true,
    title: "Craft",
    inputType: "text",
    slug: true,
  },
  delivery_time: {
    type: "string",
    required: false,
    title: "Delivery Time",
    inputType: "text",
    slug: true,
  },
  shipping: {
    type: "string",
    required: false,
    title: "Shipping",
    inputType: "text",
    slug: true,
  },
  customization: {
    type: "string",
    required: false,
    title: "Customization",
    inputType: "text",
    slug: true,
  },
  media: {
    type: "gallery",
    required: false,
    title: "Media",
    inputType: "text",
  },
  in_stock: {
    type: "checkbox",
    required: false,
    title: "In Stock",
  },
};
export const initialValues = {
  name: "",
};

export const view_all_table = [{ name: "Name", value: "name" }];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
